import { graphql } from 'gatsby';
import React from 'react';
import ConfirmationPage, { ConfirmationData } from 'templates/ConfirmationPage';
import Layout from 'components/Layout';
import replaceCsPlaceholders from 'helpers/replaceCsPlaceholders';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { ErrorType, UPDATE_ERROR } from 'state/error/actions';
import { isRenewal, Renewal, useQuote } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';
import { useUser } from 'state/user/state';

export type ConfirmationProps = {
  data: {
    csConfirmation: ConfirmationData;
  };
};

export const query = graphql`
  query {
    csConfirmation(page_id: { eq: "renewal-confirmed" }) {
      meta {
        meta_title
      }
      heading
      subheading
      image {
        ...CsAsset
      }
      cta_text
      body
    }
  }
`;

const Confirmation: React.FC<ConfirmationProps & PolicyNumberProps> = ({
  data,
  policyNumber,
}) => {
  const quote = useQuote();

  const [user] = useUser();
  usePageTracking(data.csConfirmation.meta.meta_title, !!user?.isLoggedIn && !!quote);

  const dispatch = useDispatch();

  if (!quote || !isRenewal(quote) || quote.policyNumber !== policyNumber) {
    // Likewise for Q&B error page, this would happen if they reload the confirmation page.
    dispatch({
      type: UPDATE_ERROR,
      errorType: ErrorType.NOT_FOUND,
    });
    return null;
  }

  const processedData = quote
    ? replaceCsPlaceholders(replaceQuotePlaceholders(data.csConfirmation, quote), quote, {
        policyNumber: (quoteData: Renewal) => quoteData.policyNumber,
      })
    : data.csConfirmation;

  return (
    <Layout meta={processedData.meta} pageType="mtaAndRenewal">
      <ConfirmationPage
        heading={processedData.heading}
        subheading={processedData.subheading}
        pageId={processedData.page_id}
        ctaText={processedData.cta_text}
        image={processedData.image}
      />
    </Layout>
  );
};

export default withPolicyNumber(Confirmation);
